<template>
  <div class="preliminary-results">
    <section class="preliminary-results__questions questions-section">
      <tagged-photo
        class="questions-section__tagged-photo"
        :photo="patientPhoto"
        tag="preliminaryResults.aiTag"
      />

      <div class="questions-section__questions preliminary-questions flex-column">
        <div class="field-container ">
          <span class="field-container__label u-typography-helvetica u-text u-text--s">{{
            $t('preliminaryResults.label.ageGroup')
          }}</span>
          <span class="field-container__value u-typography-gilroy u-text u-text--s mr5">{{
            $t(`preliminaryResults.ageGroup.${personalFields.ageGroup}`)
          }}</span>
        </div>
        <div
          id="age-field"
          class="preliminary-questions__age-field-container age-field-container field-container"
          :class="{ 'preliminary-questions__age-field-container--error': getFieldsError(['age']) }"
        >
          <div class="field-container__label u-typography-helvetica u-text u-text--s">
            <span>{{ $t('preliminaryResults.label.realAge') }}</span>
          </div>
          <u-simple-dropdown
            v-model="ageField"
            :placeholder="$t('preliminaryResults.label.selectYourAge')"
            :options="ageSelectOptions"
            :aria-label="$t('preliminaryResults.label.realAge')"
            right-aligned
          >
            <template #value="{ value }">
              <span
                class="age-field-container__value u-typography-gilroy u-text u-text--s"
                :class="{
                  'age-field-container__value--error': getFieldsError(['age']),
                  'age-field-container__value--placeholder': !value.value
                }"
              >
                {{ value.label }}
              </span>
            </template>
            <template #option="{option}">
              <span class="field-container__dropdown-option u-typography-gilroy u-text u-text--s">{{
                option.label
              }}</span>
            </template>
          </u-simple-dropdown>
        </div>

        <div v-for="field in personalFieldsConfig" :key="field.label" class="field-container">
          <div class="field-container__label u-typography-helvetica u-text u-text--s">
            <div>{{ field.label }}</div>

            <u-icon
              v-if="field.tooltip"
              name="help-circle"
              class="field-container__info-icon pointer"
              @click="openInfoModal(field)"
            />
          </div>
          <u-simple-dropdown
            :value="{
              value: personalFields[field.propertyName],
              label: field.options.find(({ value }) => value === personalFields[field.propertyName])
                .label
            }"
            :options="field.options"
            :aria-label="field.label"
            right-aligned
            @input="onFieldSelect(field.propertyName, $event)"
          >
            <template #value="{ value }">
              <span class="u-typography-gilroy u-text u-text--s">{{ value.label }}</span>
            </template>
            <template #option="{option}">
              <span class="field-container__dropdown-option u-typography-gilroy u-text u-text--s">{{
                option.label
              }}</span>
            </template>
          </u-simple-dropdown>
        </div>
      </div>
    </section>

    <section class="preliminary-results__scores scores-section">
      <div class="scores-section__title u-typography-helvetica u-text-display u-text--l">
        {{ $t('preliminaryResults.dysfunctionsSection.title') }}
      </div>
      <div class="scores-section__text u-typography-helvetica u-text-display u-text--xs">
        {{ $t('preliminaryResults.dysfunctionsSection.text') }}
      </div>

      <div
        class="scores-section__scores-explanation u-typography-helvetica u-text-display u-text--xs u-text-display--bold q-mb32"
      >
        {{ $t('preliminaryResults.scoringExplanation') }}
      </div>

      <skin-dysfunction-results :scores="sectionScores" :sections-binding="scoresSectionBinding" />
    </section>

    <app-info-modal />
    <phototype-info-modal />
    <skin-type-info-modal />
    <sensitive-skin-info-modal />
  </div>
</template>

<script>
import { range } from 'lodash';
import format from 'date-fns/format';

import { UIcon, USimpleDropdown } from 'universkin-shared';

import TaggedPhoto from '@/modules/questionnaire/components/common/TaggedPhoto';
import SkinDysfunctionResults from '@/modules/questionnaire/components/steps/photo-analysis/photo-analysis-results/preliminary-results/SkinDysfunctionResults';

import AppInfoModal from '@/components/modals/AppInfoModal';
import SkinTypeInfoModal from '@/modules/questionnaire/components/steps/photo-analysis/photo-analysis-results/preliminary-results/modals/SkinTypeInfoModal';
import PhototypeInfoModal from '@/modules/questionnaire/components/steps/photo-analysis/photo-analysis-results/preliminary-results/modals/PhototypeInfoModal';
import SensitiveSkinInfoModal from '@/modules/questionnaire/components/steps/photo-analysis/photo-analysis-results/preliminary-results/modals/SensitiveSkinInfoModal';

import SelectDataBinding from '@/components/common/SelectDataBinding';
import PhotoAnalysisResultSection from '@/components/common/PhotoAnalysisResultSection';

import { createDateOfBirthFromAge } from '@/utils';
import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { GENDER_VALUES } from '@/modules/questionnaire/api/constants';
import { PHOTOTYPE, SKIN_TYPE, SKIN_THICKNESS, YES_NO } from '@/constants/common';

const SELECT_OPTIONS_PROPERTY_NAMES = {
  GENDER: 'gender',
  SKIN_THICKNESS: 'skinThinkness',
  PHOTOTYPE: 'phototype',
  SENSITIVE_SKIN: 'skinReactsToProducts',
  SKIN_TYPE: 'skinTypeNorDryOilyComb'
};

const PHOTO_ANALYSIS_SELECT_OPTIONS_BINDING = [
  {
    propertyName: SELECT_OPTIONS_PROPERTY_NAMES.GENDER,
    options: GENDER_VALUES,
    keyPrefix: 'gender.'
  },
  {
    propertyName: SELECT_OPTIONS_PROPERTY_NAMES.SKIN_THICKNESS,
    options: SKIN_THICKNESS,
    keyPrefix: 'preliminaryResults.skinThickness.'
  },
  {
    propertyName: SELECT_OPTIONS_PROPERTY_NAMES.PHOTOTYPE,
    options: PHOTOTYPE,
    keyPrefix: 'preliminaryResults.phototype.'
  },
  {
    propertyName: SELECT_OPTIONS_PROPERTY_NAMES.SENSITIVE_SKIN,
    options: YES_NO,
    keyPrefix: 'label.'
  },
  {
    propertyName: SELECT_OPTIONS_PROPERTY_NAMES.SKIN_TYPE,
    options: SKIN_TYPE,
    keyPrefix: 'preliminaryResults.skinType.'
  }
];

const INFO_MODALS_CONFIG = {
  [SELECT_OPTIONS_PROPERTY_NAMES.PHOTOTYPE]: 'phototype-info-modal',
  [SELECT_OPTIONS_PROPERTY_NAMES.SKIN_TYPE]: 'skin-type-info-modal',
  [SELECT_OPTIONS_PROPERTY_NAMES.SENSITIVE_SKIN]: 'sensitive-skin-info-modal'
};

const SECTIONS_SCORE_DEFAULT_VALUES = {
  yellow: 0,
  pink: 0,
  red: 0,
  blue: 0,
  orange: 0,
  grey: 0,
  green: 0,
  brown: 0
};

const TOOLTIP_CONFIG = {
  skinThickness: 'preliminaryResults.tooltip.skinThickness',
  skinReactsToProducts: 'preliminaryResults.tooltip.sensitiveSkin',
  phototype: 'preliminaryResults.tooltip.phototype',
  skinTypeNorDryOilyComb: 'preliminaryResults.tooltip.skinType'
};

const DROPDOWN_LABEL_CONFIG = {
  age: 'preliminaryResults.label.realAge',
  gender: 'preliminaryResults.label.gender',
  phototype: 'preliminaryResults.label.photoType',
  skinTypeNorDryOilyComb: 'preliminaryResults.label.skinType',
  skinThickness: 'preliminaryResults.label.skinThickness',
  skinReactsToProducts: 'preliminaryResults.label.sensitiveSkin'
};

export const AGE_GROUPS = [
  {
    ageGroup: 'young',
    ages: range(12, 20)
  },
  {
    ageGroup: 'youngAdult',
    ages: range(20, 30)
  },
  {
    ageGroup: 'adult',
    ages: range(30, 50)
  },
  {
    ageGroup: 'senior',
    ages: range(50, 121)
  }
];

export default {
  name: 'PreliminaryResultsTemplate',
  components: {
    SensitiveSkinInfoModal,
    SkinTypeInfoModal,
    PhototypeInfoModal,
    AppInfoModal,
    TaggedPhoto,
    SkinDysfunctionResults,
    UIcon,
    USimpleDropdown
  },
  mixins: [stepTemplateMixin],
  props: {
    personalFields: {
      type: Object,
      default: () => {}
    },
    photoDiagnosticResults: {
      type: Object,
      default: () => {}
    },
    patientPhoto: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      shouldUpdateDateOfBirth: false,
      ageSelectOptions: [],
      selectOptions: [],
      sectionScores: {
        ...SECTIONS_SCORE_DEFAULT_VALUES
      }
    };
  },
  computed: {
    ageField: {
      get() {
        const { age } = this.personalFields;

        const defaultValue = this.$t('preliminaryResults.label.selectYourAge');

        return age ? { value: age, label: age } : { value: '', label: defaultValue };
      },
      set(selectedOption) {
        this.onAgeFieldSelect('age', selectedOption);
      }
    },
    selectFieldsBinding() {
      const {
        gender,
        phototype,
        skinThinkness,
        skinTypeNorDryOilyComb,
        skinReactsToProducts
      } = this.selectOptions;

      return {
        gender: new SelectDataBinding(this.$t('preliminaryResults.label.gender'), 'gender', gender),
        phototype: new SelectDataBinding(
          this.$t('preliminaryResults.label.photoType'),
          'phototype',
          phototype
        ),
        skinTypeNorDryOilyComb: new SelectDataBinding(
          this.$t('preliminaryResults.label.skinType'),
          'skinTypeNorDryOilyComb',
          skinTypeNorDryOilyComb
        ),
        skinThickness: new SelectDataBinding(
          this.$t('preliminaryResults.label.skinThickness'),
          'skinThickness',
          skinThinkness
        ),
        skinReactsToProducts: new SelectDataBinding(
          this.$t('preliminaryResults.label.sensitiveSkin'),
          'skinReactsToProducts',
          skinReactsToProducts
        )
      };
    },
    scoresSectionBinding() {
      return [
        new PhotoAnalysisResultSection({
          name: 'dysfunction.oxidativeStress',
          propertyName: 'yellow',
          color: 'oxidative-stress'
        }),
        new PhotoAnalysisResultSection({
          name: 'dysfunction.skinAppearance',
          propertyName: 'pink',
          color: 'skin-appearance'
        }),
        new PhotoAnalysisResultSection({
          name: 'dysfunction.skinRedness',
          propertyName: 'red',
          color: 'skin-redness'
        }),
        new PhotoAnalysisResultSection({
          name: 'dysfunction.drynessAndDehydration',
          propertyName: 'blue',
          color: 'skin-dryness'
        }),
        new PhotoAnalysisResultSection({
          name: 'dysfunction.skinOilness',
          propertyName: 'orange',
          color: 'skin-oiliness'
        }),
        new PhotoAnalysisResultSection({
          name: 'dysfunction.skinTexture',
          propertyName: 'grey',
          color: 'skin-texture'
        }),
        new PhotoAnalysisResultSection({
          name: 'dysfunction.pimples',
          propertyName: 'green',
          color: 'pimples'
        }),
        new PhotoAnalysisResultSection({
          name: 'dysfunction.skinPigmentation',
          propertyName: 'brown',
          color: 'skin-pigmentation'
        })
      ];
    },
    personalFieldsConfig() {
      return Object.keys(this.selectFieldsBinding).map(field => ({
        ...this.selectFieldsBinding[field],
        tooltip: TOOLTIP_CONFIG[field],
        label: this.$t(DROPDOWN_LABEL_CONFIG[field])
      }));
    }
  },
  watch: {
    fieldErrors() {
      const ageError = this.getFieldsError(['age']);

      if (ageError) {
        this.$scrollTo('#age-field', 600, { offset: -20 });
      }
    }
  },
  created() {
    this.createSelectOptions();
    this.setScoresData();

    this.shouldUpdateDateOfBirth = !this.personalFields.dateOfBirth;
  },
  methods: {
    setScoresData() {
      Object.keys(SECTIONS_SCORE_DEFAULT_VALUES).forEach(scoreField => {
        this.sectionScores[scoreField] = this.photoDiagnosticResults[scoreField];
      });
    },
    createSelectOptions() {
      this.ageSelectOptions = range(12, 121).map(key => ({
        value: key,
        label: key
      }));

      PHOTO_ANALYSIS_SELECT_OPTIONS_BINDING.forEach(({ propertyName, options, keyPrefix }) => {
        this.selectOptions[propertyName] = options.map(value => ({
          label: this.$t(`${keyPrefix}${value}`),
          value
        }));
      });
    },
    onAgeFieldSelect(propertyName, option) {
      this.onFieldChange(propertyName, option.value);

      const { ageGroup } = AGE_GROUPS.find(({ ages }) => ages.includes(option.value));

      if (ageGroup !== this.personalFields.ageGroup) {
        this.onFieldChange('ageGroup', ageGroup);
      }

      if (this.shouldUpdateDateOfBirth) {
        const dateOfBirth = createDateOfBirthFromAge(option.value);

        this.onFieldChange('dateOfBirth', format(dateOfBirth, 'YYYY-MM-DD'));
      }
    },
    onFieldSelect(propertyName, option) {
      this.onFieldChange(propertyName, option.value);
    },
    openInfoModal({ propertyName, label: title, tooltip: details }) {
      const modalName = INFO_MODALS_CONFIG[propertyName] || 'app-info-modal';

      this.$asyncModal.show(modalName, { title, details });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes';

.preliminary-results {
  min-width: 285px;
  width: 100%;
  height: auto;
  padding: 24px 10px;
  border: 1px solid var(--u-color-grey-200);
  border-radius: 10px;
  background-color: var(--u-color-white);

  &__questions {
    display: block;
    margin-bottom: 24px;
  }

  &__scores {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.questions-section {
  &__tagged-photo {
    margin-bottom: 10px;
  }

  &__questions {
    min-width: 270px;
    width: 100%;
    margin: 0 auto;
  }
}

.field-container {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--u-color-grey-200);

  &__label {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: var(--u-color-grey-600);
  }

  &__info-icon {
    margin-left: 10px;
    --u-icon-color: var(--u-color-grey-400);
  }

  &__value {
    color: var(--u-color-graphite);
  }

  &__dropdown-option {
    white-space: nowrap;
  }

  &--error {
    border-bottom: 1px solid var(--u-color-error);
  }
}

.preliminary-questions {
  &__age-field-container {
    height: 58px;
    padding: 0 14px 0 12px;
    margin: 16px 0 3px;

    border: 1px solid var(--u-color-grey-200);
    border-radius: 10px;

    &--error {
      border-color: var(--u-color-error);
    }
  }
}

.age-field-container {
  &__value {
    &--placeholder {
      color: var(--u-color-grey-500);
      font-style: italic;
    }

    &--error {
      color: var(--u-color-error);
    }
  }
}
.scores-section {
  &__text {
    color: var(--u-color-grey-600);
    min-width: 270px;
    width: 100%;
    margin: 8px auto;

    text-align: center;
  }

  &__scores-explanation {
    padding: 8px 16px;

    text-align: center;
    background: var(--u-color-blue-50);
    border-radius: 10px;
  }
}

@media (min-width: $tablet-start) {
  .preliminary-results {
    width: 556px;
    max-width: inherit;
    padding: 24px 32px;

    &__questions {
      margin-bottom: 32px;
    }

    &__scores {
      position: relative;
      margin-top: 32px;
      text-align: center;
      color: var(--u-color-graphite);
    }
  }

  .questions-section {
    &__tagged-photo {
      margin-bottom: 18px;
    }

    &__questions {
      width: 100%;
    }
  }
}
</style>
