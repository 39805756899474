<template>
  <info-modal-template
    name="app-info-modal"
    :title="title"
    :details="details"
    @close="onCloseHandler"
    @before-open="onBeforeOpen"
  />
</template>

<script>
import InfoModalTemplate from '@/components/modals/templates/InfoModalTemplate';

export default {
  name: 'AppInfoModal',
  components: { InfoModalTemplate },
  data() {
    return {
      title: null,
      details: null,
      onClose: null
    };
  },
  methods: {
    onBeforeOpen({ params: { onClose, title, details } }) {
      this.onClose = onClose;
      this.title = title;
      this.details = details;
    },
    onCloseHandler() {
      this.onClose();
    }
  }
};
</script>
