<template>
  <info-modal-template
    name="phototype-info-modal"
    title="preliminaryResults.label.photoType"
    details="preliminaryResults.tooltip.phototype"
    @close="onCloseHandler"
    @before-open="onBeforeOpen"
  >
    <template #prepend>
      <div class="phototype-info-images flex--align-center">
        <div
          v-for="{ image, text } in $options.phototypeImages"
          :key="text"
          class="phototype-info-images__image-container flex-column--align-center"
        >
          <img class="phototype-info-images__image" :src="image" :alt="text" />
          <p class="phototype-info-images__caption u-typography-helvetica u-text u-text--xs">
            <b>{{ text }}</b>
          </p>
        </div>
      </div>
    </template>
  </info-modal-template>
</template>

<script>
import InfoModalTemplate from '@/components/modals/templates/InfoModalTemplate';

import blackFaceImage from '@/modules/questionnaire/assets/images/face-features/male/skin-color/black.svg';
import darkBrownFaceImage from '@/modules/questionnaire/assets/images/face-features/male/skin-color/dark_brown.svg';
import lightBrownFaceImage from '@/modules/questionnaire/assets/images/face-features/male/skin-color/light_brown.svg';
import mediumBrownFaceImage from '@/modules/questionnaire/assets/images/face-features/male/skin-color/medium_brown.svg';
import paleWhiteFaceImage from '@/modules/questionnaire/assets/images/face-features/male/skin-color/pale_white.svg';
import veryPaleWhiteFaceImage from '@/modules/questionnaire/assets/images/face-features/male/skin-color/very_pale_white.svg';

const PHOTOTYPE_IMAGES = [
  { image: veryPaleWhiteFaceImage, text: 'I' },
  { image: paleWhiteFaceImage, text: 'II' },
  { image: lightBrownFaceImage, text: 'III' },
  { image: mediumBrownFaceImage, text: 'IV' },
  { image: darkBrownFaceImage, text: 'V' },
  { image: blackFaceImage, text: 'VI' }
];

export default {
  name: 'PhototypeInfoModal',
  components: { InfoModalTemplate },
  phototypeImages: PHOTOTYPE_IMAGES,
  data() {
    return {
      onClose: null
    };
  },
  methods: {
    onBeforeOpen({ params: { onClose } }) {
      this.onClose = onClose;
    },
    onCloseHandler() {
      this.onClose();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes';

.phototype-info-images {
  margin-top: 16px;
  flex-wrap: wrap;

  &__image-container:not(:last-child) {
    margin-right: 16px;
  }

  &__image {
    width: 31px;
  }

  &__caption {
    margin: 8px 0 0;
    color: var(--u-color-grey-900);
  }
}

@media (min-width: $tablet-start) {
  .phototype-info-images {
    &__image-container {
      margin-right: 21px;
    }

    &__image {
      width: 32px;
    }
  }
}
</style>
