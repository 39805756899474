export const PHOTOTYPE = [
  'phototype1',
  'phototype2',
  'phototype3',
  'phototype4',
  'phototype5',
  'phototype6'
];

export const SKIN_THICKNESS = ['thin', 'thick'];

export const SKIN_TYPE = ['normal', 'dry', 'oily', 'combination'];

export const YES = ['yes'];
export const NO = ['no'];

export const YES_NO = [...YES, ...NO];

export const ACTIVES = {
  OXIDATIVE_STRESS: 'oxidativeStress',
  SKIN_APPEARANCE: 'skinAppearance',
  SKIN_REDNESS: 'skinRedness',
  SKIN_DRYNESS: 'skinDryness',
  SKIN_OILINESS: 'skinOiliness',
  SKIN_TEXTURE: 'skinTexture',
  PIMPLES: 'pimples',
  SKIN_PIGMENTATION: 'skinPigmentation'
};
