var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"preliminary-results"},[_c('section',{staticClass:"preliminary-results__questions questions-section"},[_c('tagged-photo',{staticClass:"questions-section__tagged-photo",attrs:{"photo":_vm.patientPhoto,"tag":"preliminaryResults.aiTag"}}),_c('div',{staticClass:"questions-section__questions preliminary-questions flex-column"},[_c('div',{staticClass:"field-container "},[_c('span',{staticClass:"field-container__label u-typography-helvetica u-text u-text--s"},[_vm._v(_vm._s(_vm.$t('preliminaryResults.label.ageGroup')))]),_c('span',{staticClass:"field-container__value u-typography-gilroy u-text u-text--s mr5"},[_vm._v(_vm._s(_vm.$t(("preliminaryResults.ageGroup." + (_vm.personalFields.ageGroup)))))])]),_c('div',{staticClass:"preliminary-questions__age-field-container age-field-container field-container",class:{ 'preliminary-questions__age-field-container--error': _vm.getFieldsError(['age']) },attrs:{"id":"age-field"}},[_c('div',{staticClass:"field-container__label u-typography-helvetica u-text u-text--s"},[_c('span',[_vm._v(_vm._s(_vm.$t('preliminaryResults.label.realAge')))])]),_c('u-simple-dropdown',{attrs:{"placeholder":_vm.$t('preliminaryResults.label.selectYourAge'),"options":_vm.ageSelectOptions,"aria-label":_vm.$t('preliminaryResults.label.realAge'),"right-aligned":""},scopedSlots:_vm._u([{key:"value",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"age-field-container__value u-typography-gilroy u-text u-text--s",class:{
                'age-field-container__value--error': _vm.getFieldsError(['age']),
                'age-field-container__value--placeholder': !value.value
              }},[_vm._v(" "+_vm._s(value.label)+" ")])]}},{key:"option",fn:function(ref){
              var option = ref.option;
return [_c('span',{staticClass:"field-container__dropdown-option u-typography-gilroy u-text u-text--s"},[_vm._v(_vm._s(option.label))])]}}]),model:{value:(_vm.ageField),callback:function ($$v) {_vm.ageField=$$v},expression:"ageField"}})],1),_vm._l((_vm.personalFieldsConfig),function(field){return _c('div',{key:field.label,staticClass:"field-container"},[_c('div',{staticClass:"field-container__label u-typography-helvetica u-text u-text--s"},[_c('div',[_vm._v(_vm._s(field.label))]),(field.tooltip)?_c('u-icon',{staticClass:"field-container__info-icon pointer",attrs:{"name":"help-circle"},on:{"click":function($event){return _vm.openInfoModal(field)}}}):_vm._e()],1),_c('u-simple-dropdown',{attrs:{"value":{
            value: _vm.personalFields[field.propertyName],
            label: field.options.find(function (ref) {
                      var value = ref.value;

                      return value === _vm.personalFields[field.propertyName];
              })
              .label
          },"options":field.options,"aria-label":field.label,"right-aligned":""},on:{"input":function($event){return _vm.onFieldSelect(field.propertyName, $event)}},scopedSlots:_vm._u([{key:"value",fn:function(ref){
          var value = ref.value;
return [_c('span',{staticClass:"u-typography-gilroy u-text u-text--s"},[_vm._v(_vm._s(value.label))])]}},{key:"option",fn:function(ref){
          var option = ref.option;
return [_c('span',{staticClass:"field-container__dropdown-option u-typography-gilroy u-text u-text--s"},[_vm._v(_vm._s(option.label))])]}}],null,true)})],1)})],2)],1),_c('section',{staticClass:"preliminary-results__scores scores-section"},[_c('div',{staticClass:"scores-section__title u-typography-helvetica u-text-display u-text--l"},[_vm._v(" "+_vm._s(_vm.$t('preliminaryResults.dysfunctionsSection.title'))+" ")]),_c('div',{staticClass:"scores-section__text u-typography-helvetica u-text-display u-text--xs"},[_vm._v(" "+_vm._s(_vm.$t('preliminaryResults.dysfunctionsSection.text'))+" ")]),_c('div',{staticClass:"scores-section__scores-explanation u-typography-helvetica u-text-display u-text--xs u-text-display--bold q-mb32"},[_vm._v(" "+_vm._s(_vm.$t('preliminaryResults.scoringExplanation'))+" ")]),_c('skin-dysfunction-results',{attrs:{"scores":_vm.sectionScores,"sections-binding":_vm.scoresSectionBinding}})],1),_c('app-info-modal'),_c('phototype-info-modal'),_c('skin-type-info-modal'),_c('sensitive-skin-info-modal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }